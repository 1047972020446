import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  formatAmount(event) {
    let value = event.target.value

    // Remove all non-numeric characters except for decimal points
    value = value.replace(/[^\d.]/g, '')

    // Split the value into integer and decimal parts
    let [integerPart, decimalPart] = value.split('.')

    // Format the integer part with commas
    if (integerPart) {
      integerPart = parseInt(integerPart, 10).toLocaleString('en-US')
    }

    // Reassemble the formatted value
    const formattedValue =
      decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart

    event.target.value = formattedValue
  }
}
